export const colors = [
    "76C04B",
    "8D1840",
    "A44098",
    "767FBE",
    "41674F",
    "FBA919",
    "34499E",
    "9D9A51",
    "ED2024",
    "FCC28E",
    "E2E419",
    "5F3092",
    "865F94",
    "5B8AAE",
    "DD4297",
    "B86328"
];
