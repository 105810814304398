import React from "react";

export default function HelpTab() {
    return (
        <div className="help-tab">
            <section>
                <h3>ABOUT</h3>
                <p>
                This platform was created by <a target="_blank" href='https://hesterstreet.org/'>Hester Street</a> in partnership with <a href='http://stuartlynn.me' target="_blank">Stuart Lynn</a>. Hester Street is an urban planning, design and development nonprofit that works to ensure neighborhoods are shaped by the people who live in them.</p>
                <p>
                If you would like to learn more about this map or discuss its features please email <a href="mailto:vote@hesterstreet.org">vote@hesterstreet.org</a>. 
                </p>
            </section>
        </div>
    );
}
